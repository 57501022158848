import React from 'react';
import './footer1.less';

import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Row, Col,Popover } from 'antd';
import { getChildrenToRender,isImg } from '@/utils/utils';
import Footer2 from './Footer2';
import { Footer21DataSource } from '../data.source';

class Footer extends React.Component {
  static defaultProps = {
    className: 'footer1',
  };
  getQrcodeRender = (data)=>{
    const content = (
      <div style={{width:'160px',}}>
        <img src={data.children} width="100%" alt="img" />
      </div>
    )
    return(
      <div {...data} key={data.children}>
        {/*<Popover content={content} >*/}
          <img src={data.children} width="100%" alt="img" />
        {/*</Popover>*/}
        {/* {data.children} */}
      </div>
    )
  }
  getLiChildren = (data) =>
    data.map((item, i) => {
      const { title, childWrapper, ...itemProps } = item;
      return (
        <Col key={i.toString()} {...itemProps} title={null} content={null}>
          <h2 {...title}>
            {typeof title.children === 'string' &&
            title.children.match(isImg) ? (
              <img src={title.children} width="100%" alt="img" />
            ) : (
              title.children
            )}
          </h2>
          <div {...childWrapper}>
            {
              itemProps.name==="qrcode" ? childWrapper.children.map(this.getQrcodeRender) : childWrapper.children.map(getChildrenToRender) 
            }
      
          </div>
        </Col>
      );
    });


  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    const childrenToRender = this.getLiChildren(dataSource.block.children);
    // const qrcodeRender = this.getQrcode(dataSource.qrcode.children);
    return (
      <div {...props} {...dataSource.wrapper}>
        <OverPack {...dataSource.OverPack}>
          <QueueAnim
            type="bottom"
            key="ul"
            leaveReverse
            component={Row}
            {...dataSource.block}
          >
            {childrenToRender}
            {/* {qrcodeRender} */}
          </QueueAnim>

          <Footer2 dataSource={Footer21DataSource}/>
          <TweenOne
            animation={{ y: '+=30', opacity: 0, type: 'from' }}
            key="copyright"
            {...dataSource.copyrightWrapper}
          >
            <div {...dataSource.copyrightPage}>
              <div {...dataSource.copyright}>
                {dataSource.copyright.children}
              </div>
            </div>
            <div className='backup-num'>
            <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备17007010号-3</a>
            </div>
          </TweenOne>
        </OverPack>
      </div>
    );
  }
}

export default Footer;
