import React from 'react';
import axios from "axios";
import Qs from 'qs'
import {message} from 'antd';

import {
    Form,
    Input,
    Select,
    Button,
} from 'antd';

const {Option} = Select;
const {TextArea} = Input;


class RegistrationForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmDirty: false,
            laoding: false,
            autoCompleteResult: [],
        };
    }

    handleSubmit = e => {
        e.preventDefault()
        this.setState({loading: true});
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const dataSource = this.props.dataSource
                axios.post('https://aiwebsitemail.aicalming.com:7001', Qs.stringify({
                    message: `姓名:${values.name}<br>
            电话:${values.phone}<br>
            邮箱:${values.email}<br>
            公司类型:${dataSource.options.companyType[values.type].text}<br>
            所属行业:${dataSource.options.trade[values.trade].text}<br>
            岗位:${values.job}<br>
            关注产品:${dataSource.options.prod[values.prod].text}<br>
            详细需求:${values.detail}<br>`
                })).then(res => {
                    if (res.status === 200) {
                        message.success('提交成功！请耐心等候联系');
                    }
                }).catch(ierr => {
                    message.warning(JSON.stringify(ierr));
                }).finally(() => {
                    this.setState({loading: false});
                })
            } else {
                this.setState({loading: false});
            }
        });
    };

    handleConfirmBlur = e => {
        const {value} = e.target;
        this.setState({confirmDirty: this.state.confirmDirty || !!value});
    };

    compareToFirstPassword = (rule, value, callback) => {
        const {form} = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };


    handleWebsiteChange = value => {
        let autoCompleteResult;
        if (!value) {
            autoCompleteResult = [];
        } else {
            autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
        }
        this.setState({autoCompleteResult});
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {...props} = this.props;

        const {dataSource} = props;
        delete props.dataSource;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 12,
                    offset: 9,
                },
            },
        };
        const prefixSelector = getFieldDecorator('prefix', {
            initialValue: '86',
        })(
            <Select style={{width: 70}}>
                <Option value="86">+86</Option>
            </Select>,
        );


        return (
            <Form layout='horizontal' labelAlign={'left'} {...formItemLayout} onSubmit={this.handleSubmit}>
                <Form.Item label="姓名">
                    {getFieldDecorator('name', {
                        rules: [
                            {
                                required: true,
                                message: '请输入您的联系姓名！',
                            },
                        ],
                    })(<Input/>)}
                </Form.Item>

                <Form.Item label="电话">
                    {getFieldDecorator('phone', {
                        rules: [{required: true, message: '请输入您的联系电话！'}],
                    })(<Input addonBefore={prefixSelector}/>)}
                </Form.Item>
                <Form.Item label="邮箱">
                    {getFieldDecorator('email', {
                        rules: [
                            {
                                type: 'email',
                                message: '请输入邮箱！',
                            },
                            {
                                required: false,
                                message: '请输入您的邮箱！',
                            },
                        ],
                    })(<Input/>)}
                </Form.Item>
                {/*<Form.Item label="所在地区" >*/}
                {/*  {getFieldDecorator('location', {*/}
                {/*    rules: [*/}
                {/*      {*/}
                {/*        required: true,*/}
                {/*        message: '请输入您的所在地！',*/}
                {/*      },*/}
                {/*    ],*/}
                {/*  })(<Input />)}*/}
                {/*</Form.Item>*/}
                <Form.Item label="公司">
                    {getFieldDecorator('company', {
                        rules: [
                            {
                                required: true,
                                message: '请输入您的公司！',
                            },
                        ],
                    })(<Input/>)}
                </Form.Item>
                <Form.Item label="岗位">
                    {getFieldDecorator('job', {
                        rules: [
                            {required: true, message: '请选择您的职务岗位！'},
                        ],
                    })(<Input/>)}
                </Form.Item>


                <Form.Item label="公司类别">
                    {getFieldDecorator('type', {
                        initialValue: ['0'],
                        rules: [
                            {required: true, message: '请选择您的公司类别！'},
                        ],
                    })(<Select style={{width: '100%'}}>
                        {dataSource.options.companyType.map(item => {
                            return <Option key={item.val} value={item.val}>{item.text}</Option>
                        })}
                    </Select>)}
                </Form.Item>

                <Form.Item label="所属行业">
                    {getFieldDecorator('trade', {
                        initialValue: ['0'],
                        rules: [
                            {required: false, message: '请选择您公司所属行业！'},
                        ],
                    })(<Select style={{width: '100%'}}>
                        {dataSource.options.trade.map(item => {
                            return <Option key={item.val} value={item.val}>{item.text}</Option>
                        })}
                    </Select>)}
                </Form.Item>
                <Form.Item label="关注产品">
                    {getFieldDecorator('prod', {
                        initialValue: ['0'],
                        rules: [
                            {required: false, message: '请选择试用产品！'},
                        ],
                    })(<Select style={{width: '100%'}}>
                        {dataSource.options.prod.map(item => {
                            return <Option key={item.val} value={item.val}>{item.text}</Option>
                        })}
                    </Select>)}
                </Form.Item>

                <Form.Item label="详细需求">
                    {getFieldDecorator('detail',{
                      rules: [
                        {required: true, message: '请简单表述一下您的需求！'},
                      ],
                    })(<TextArea rows={4} placeholder='请简单表述一下您的需求'/>)}
                </Form.Item>
                {/* <Form.Item {...tailFormItemLayout}>
            {getFieldDecorator('agreement', {
              valuePropName: 'checked',
            })(
              <Checkbox>
                I have read the <a href="">agreement</a>
              </Checkbox>,
            )}
          </Form.Item> */}
                <Form.Item {...tailFormItemLayout}>
                    <Button loading={this.state.loading} type="primary" htmlType="submit" block size='large'>
                        提交需求
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

const WrappedRegistrationForm = Form.create({name: 'register'})(RegistrationForm);
export default WrappedRegistrationForm;

//   ReactDOM.render(<WrappedRegistrationForm />, mountNode);