/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import {enquireScreen} from 'enquire-js';
import {Row, List} from 'antd';


import Form from './Form';
import Location from './Location';


import './less/antMotionStyle.less';


import {Contact00DataSource, FormDataSource} from './data.source'

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const dataSource = {
    wrapper: {className: 'form form-page-wrapper'},
    page: {className: 'form-page'},
};


const location = window.location;
export default class Connect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({isMobile: !!b});
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */
    }

    render() {
        const children = [
            <Form
                id="Form_0"
                key="Form_0"
                dataSource={FormDataSource}
                isMobile={this.state.isMobile}
            />
        ];
        const data = [
            {
                title: '商务合作',
                detail: <span>+86 180-4999-2915 <br/> ai@aicalming.com</span>,
            },
            // {
            //     title: '简历投递',
            //     tel: '',
            //     detail: 'hr@calming.com.cn'
            // }
        ];
        return (
            <div
                className="connect-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
            >

                <div {...dataSource.wrapper}>
                    <div {...dataSource.page}>
                        <div className='contact-left'>
                            <Row>
                                <h1 style={{marginBottom: '10px'}}>上海可明科技有限公司</h1>
                                <div>上海市徐汇区漕宝路181号和光天地B栋6层</div>
                                <div>近地铁12号/15号桂林公园站5号口</div>
                                <List
                                    style={{marginTop: '44px'}}
                                    itemLayout="horizontal"
                                    dataSource={data}
                                    size='large'
                                    footer='&nbsp;'
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={item.title}
                                                description={<a href={'mailto:' + item.detail}>{item.detail}</a>}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Row>
                            {children}

                        </div>
                        <div className='contact-right'>
                            <Location
                                id="Contact0_0"
                                key="Contact0_0"
                                dataSource={Contact00DataSource}
                                isMobile={this.state.isMobile}
                            />

                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
